const questions = {
    B: {
        question: "What else do you like?",
        answer: null,
        answerOptions: [{
            answer:"Your personality.",
            text: "Thanks!"
        },{
            answer:"Your humor.",
            text: "Funny."
        }],
        prev:"A",
        next:"RESULTS"
    },
    A : {
        question: "I feel confident relying on my residents to accurately self-report any potential health or safety issues in a timely manner.",
        category: "problems",
        answer: null,
        correctAnswer: "Disagree",
        answerOptions: [{
            answer:"Agree",
            text: "You must have residents that are very self-aware and not at all self-conscious!"
        },{
            answer:"Disagree",
            text: "Many residents are unaware of changes to their own health status or are self-conscious about reporting many symptoms."
        }],
        prev: null,
        next: "C"
    },
    C: {
        question: "I feel that my staff knows what is going on inside resident rooms 24/7.",
        category: "problems",
        answer: null,
        correctAnswer: "Disagree",
        answerOptions: [{
            answer:"Agree",
            text: "You must have a 1:1 staffing ratio or very sedentary residents!"
        },{
            answer:"Disagree",
            text: "Because people value their privacy and independence, even the most well-staffed facilities still have “blind spots”, especially at night and during holidays."
        }],
        prev: "A",
        next: "D"
    },
    D: {
        question: "I believe that better data about resident behavior (and changes) will lead to improved health outcomes and reduced falls.",
        category: "problems",
        answer: null,
        correctAnswer: "Agree",
        answerOptions: [{
            answer:"Agree",
            text: "It is well-documented that faster detection of health status changes reduces the risk of hospitalization. It is also well-understood that falls can be reduced by addressing root causes, such as unsafe ambulation or excessive night-time movement."
        },{
            answer:"Disagree",
            text: "You must have a 1:1 staffing ratio or very sedentary residents!"
        }],
        prev: "C",
        next: "E"
    },
    E: {
        question: "I believe that functional mobility is a good indicator of overall health, and that (generally speaking) movement should be encouraged to improve health and reduce fall risk.",
        category: "problems",
        correctAnswer: "Agree",
        answer: null,
        answerOptions: [{
            answer:"Agree",
            text: "Indeed, residents who move more often are less likely to become deconditioned, leading to fall risk and exacerbation of underlying health issues."
        },{
            answer:"Disagree",
            text: "Generally speaking, residents who move more often are actually less likely to fall, as they do not become deconditioned. Lack of movement can lead to exacerbation of medical issues such as diabetes, CHF, pressure injuries, and more."
        }],
        prev: "D",
        next: "F"
    },
    F: {
        question: "I think it would be a good idea to put cameras in resident rooms and bathrooms to get a better sense of safety and health issues.",
        category: "technology",
        correctAnswer: "Disagree",
        answer: null,
        answerOptions: [{
            answer:"Agree",
            text: "Unless the resident is suffering from severe dementia, most residents see cameras as an unacceptable level of surveillance."
        },{
            answer:"Disagree",
            text: "Most residents see cameras as an invasion of privacy. However, cameras may still be a necessity for the safety of those suffering from severe cognitive deficits."
        }],
        prev: "E",
        next: "G"
    },
    G: {
        question: "I think my residents would be compliant in wearing a smartwatch 24/7, even when they are sleeping.",
        category: "technology",
        answer: null,
        correctAnswer: "Disagree",
        answerOptions: [{
            answer:"Agree",
            text: "You must have very tech-savvy residents!"
        },{
            answer:"Disagree",
            text: "This is a common problem with wearables: the information gathered at night is most useful, but people don’t want to sleep with them on."
        }],
        prev: "F",
        next: "H"
    },
    H: {
        question: "I think my residents would charge a smartwatch every day (though not at night).",
        category: "technology",
        answer: null,
        correctAnswer: "Disagree",
        answerOptions: [{
            answer:"Agree",
            text: "You must have very tech-savvy residents!"
        },{
            answer:"Disagree",
            text: "This is another common problem with wearables: the market leaders need to be charged every day or a staff member has to change every battery every two days."
        }],
        prev: "G",
        next: "I"
    },
    I: {
        question: "I think smartwatches will record movement data when a resident uses a walker or rollator.",
        category: "technology",
        answer: null,
        correctAnswer: "Disagree",
        answerOptions: [{
            answer:"Agree",
            text: "Unfortunately, because using a walker or rollator limits wrist movement, smartwatches will not record activity when a resident uses a mobility aid."
        },{
            answer:"Disagree",
            text: "Correct: using a walker or rollator limits wrist movement, and thus smartwatches will not record activity when a resident uses a mobility aid."
        }],
        prev: "H",
        next: "J"
    },
    J: {
        question: "Approximately what percentage of your residents use walker, rollators, or canes to ambulate?",
        answer: null,
        answerOptions: [{
            answer:"None",
            text: "Okay!",
        },{
            answer:"25%",
            text: "Great.",
        },{
            answer:"50%",
            text: "Great."
        },{
            answer:"75%",
            text: "Great."
        },{
            answer:"100%",
            text: "Great."
        }],
        prev: "I",
        next: "K"
    },
    K: {
        question: "Approximately what percentage of your residents use self-propelled wheelchairs? (Keep in mind, there may be resident in both groups)",
        answer: null,
        answerOptions: [{
            answer:"None",
            text: "Great.",
        },{
            answer:"25%",
            text: "Great.",
        },{
            answer:"50%",
            text: "Great."
        },{
            answer:"75%",
            text: "Great."
        },{
            answer:"100%",
            text: "Wow!"
        }],
        prev: "J",
        next: "RESULTS"
    }
}
    
export default questions;