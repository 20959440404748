// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// ReactDOM.render(<App />, document.getElementById('root'));

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#800080',
    },
    secondary: {
      main: '#f50057',
    },
  },
  spacing: 8,
  components: {
    // Name of the component
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: 10,
          margin: 5,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 100,
        }
      }
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeOptions}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

