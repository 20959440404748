import { useState, useEffect, useReducer } from 'react';
import { Button, Input } from '@mui/material';

import './App.css';

import questions from './questions';


function reducer(state,action){
  if (action.type === "answer"){
    var _s = {...state};
    _s[action.data.id].answer = action.data.answer;
    return _s
  }
}

function App() {

  const [state,dispatch] = useReducer(reducer,questions);

  const [qIndex,setQIndex] = useState("A");

  const [page,setPage] = useState("questions");

  var question = state[qIndex];

  const prev = function(){
    if (question.prev !== null){
      setQIndex(question.prev);
    }
  }

  const next = function(){
    if (question.next !== "RESULTS"){
      setQIndex(question.next);
    } else {
      setPage("results");
    }
  }

  

  return (
    <div className="App" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <div style={{height: '15%'}}/>
      <div style={{flex: 1,maxWidth:550,padding:10}}>
        {page === "results" ? <Results data={state} goBack={()=>{setPage("questions")}}/>
          :<Question q={question} id={qIndex} prev={prev} next={next} dispatch={dispatch} />}
      </div>
      <div style={{height: '25%'}}/>
    </div>
  );
}

function Question({q,id,prev,next,dispatch}){

  var resultText = null;
  if (q.answerOptions && q.answer){
    resultText = q.answerOptions.find(a => a.answer === q.answer).text;
  }

  return <div>
    <div style={{height:200,display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
     <h3>{q.question}</h3>
    </div>
    {q.answerOptions.map(a => {
      return <Answer key={`${id}-${a.answer}`} id={id} answer={a.answer} selected={a.answer === q.answer} dispatch={dispatch}/>
    })}
    <div style={{height:200}}>
      <p>{resultText}</p>
    </div>
    
    <Button onClick={prev} disabled={q.prev === null}>Go back</Button>
    <Button disabled={q.answer === null} variant="outlined" onClick={next}>{q.next === "RESULTS" ? "FINISH" : "NEXT"}</Button>
  </div>
}

function Answer({answer,id,selected,dispatch}){
  function select(){
    dispatch({type:"answer",data:{
      id:id,
      answer:answer
    }});
  }

  return <Button variant={selected?"contained":"outlined"} onClick={select}>
    {answer}
  </Button>
}

function Results({data,goBack}){

  const [email,setEmail] = useState("");

  const [status,setStatus] = useState("none");

  const [resultText,setResultText] = useState("");

  // useEffect(()=>{
  //   if (status === "sent"){
  //     setStatus("none");
  //   }
  // },[email])

  useEffect(()=>{
    var text = getResult(data);
    setResultText(text);
    setStatus("none");
  },[data]);

  var send = async function(){
    setStatus("loading");
    const response =  await fetch(`/api/sendEmail`,{
      method:"POST",
      body: JSON.stringify({ email: email, data: data, resultText: resultText }),
    });

    if (response.ok){
      setStatus("sent");
    } else {
      setStatus("error");
    }
  }

  

  return <div>
      <h2 style={{color:"#800080"}}>Here are your results:</h2>
        <h3>{resultText}</h3>
        <p>Send answers and result to your email:</p>
        <Input placeholder="Email" value={email} onChange={e=>setEmail(e.target.value)}/>
        <Button variant="contained" disabled={status === "loading" || status === "sent"} onClick={send}>Send</Button>
      <div style={{height: 60}}>
        {status === "loading" ? <p style={{color:'#800080'}}>Sending...</p>:null}
        {status === "sent" ? <p style={{color:'#800080'}}>Successfully Sent!</p>:null}
        {status === "error" ? <p style={{color:'red'}}>Error Sending Email</p>:null}
      </div>
      <br/>
      <Button onClick={goBack}>Back to Questions</Button>
      <br/>
      <Button onClick={()=>{window.location.reload();}}>Start Over</Button>
  </div>
}

function getResult(data){
  var text = "You are doing a great job.";

  var problemsCorrect = [];
  var technologyCorrect = [];

  Object.values(data).forEach(q=>{
    if (q.correctAnswer){
      var result = q.answer === q.correctAnswer ? 1 : 0;
      if (q.category === "problems"){
        problemsCorrect.push(result);
      } else if (q.category === "technology"){
        technologyCorrect.push(result);
      }
    }
  });

  var problemsScore = problemsCorrect.reduce((x,last)=>{return last + x})/problemsCorrect.length;
  var technologyScore = technologyCorrect.reduce((x,last)=>{return last + x})/technologyCorrect.length;
  //console.log(`ProblemsScore: ${problemsScore}`);
  //console.log(`TechnologyScore: ${technologyScore}`);


  if (problemsScore < 0.75){
    text = `Based on these answers, you rely heavily on your care team to 1) notice any issues with residents, 2) timely and accurately report these issues to the appropriate discipline. Even if you do not have high levels of turnover, relying on staff who are not with residents 24/7 to detect any issue can be challenging and concerning behaviors often slip through the cracks. It may be worth your time to investigate technologies that can supplement your staff’s observations and provide “eyes-on” residents when staff in not present.`;
  } else if (technologyScore < 0.75){
    text = `Based on these answers, you understand the value of technology to supplement the observations of your staff. It may be worth your time to better understand what technologies might be acceptable to your residents and staff, keeping in mind that privacy and ease-of-use are important metrics to consider.`;
  } else {
    text = `You clearly understand the pitfalls facing many of the existing technologies on the market. However, you also understand the importance of using monitoring tools to keep residents safe and healthy, since even the best care staff cannot possibly detect all issues 24/7. Well done!`;
  }

  return text;
}

export default App;



// import React, {useEffect, useState} from 'react';
// import { Button } from '@mui/material';

// function App() {

//   const [data,setData] = useState(null);

//   async function call(){
//     const text = await( await fetch(`/api/sendEmail`,{
//       method:"POST",
//       body: JSON.stringify({ name: "Peter" }),
//     })).text();
//     setData(text);
//   }


//   const value = 'World';
//   return <div>
//     <p>Hello {value}</p>
//     <Button onClick={call}>Get Data</Button>
//     <p>{data}</p>
//   </div>;
// }

// export default App;
